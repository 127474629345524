.container {
  background-color: var(--color-white-background);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 25px;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy Bold';
}

.title img {
  margin-bottom: 4px;
}

.link {
  border: 1px solid var(--color-orange-primary);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  margin-top: 10px;
}

.container a {
  text-decoration: none;
  color: var(--color-orange-primary);
  padding-top: 2px;
}

.with-photos {
  position: relative;
}

@media (min-width: 600px) {
  .container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
  }
  .link {
    margin: 0;
  }
}
