.container {
  position: relative;
}

.container img {
  width: 20px;
  height: 20px;
}

.localization {
  position: absolute;
  top: 28px;
  right: 15px;
}
