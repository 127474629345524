.content {
  padding: 15px;
  border-radius: 20px 20px 0 0;
  background-color: var(--color-white-background);
}

.content button {
  width: 100%;
  margin-top: 30px;
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 600px) {
  .wrapper {
    background-color: var(--color-grey-background);
    padding: 40px;
  }
  .content {
    padding: 15px;
    border-radius: 20px;
    background-color: var(--color-white-background);
    max-width: 1000px;
    margin: 0 auto;
  }
}
