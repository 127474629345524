.wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.photos-grid {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: 150px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  cursor: pointer;
}

.photos-grid.one-column {
  grid-template-columns: 1fr;
}

.photos-grid.two-rows {
  grid-template-rows: 75px 75px;
}

.photos-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box-1 {
  grid-row: 1 / 2;
}

.two-rows .box-1 {
  grid-row: 1 / 3;
}

.box-2 {
  grid-column: 2;
}

.box-3 {
  grid-column: 2;
  position: relative;
}

.photos-count {
  color: var(--color-white);
  font-size: 27px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 10px 10px 50px var(--color-shadow);
  width: 100%;
}

.container.with-photos {
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container:last-child {
  margin-bottom: 30px;
}

.date-photo-name {
  border-bottom: 1px solid var(--color-grey-extralight);
  font-family: 'Gilroy Bold', 'sans-serif';
}

.date-photos {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
}

.title {
  padding: 0 0 15px 20px;
}

.photo {
  width: 80px;
  height: 80px;
  border-radius: 16px;
}

.info {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 5px;
  padding: 20px;
  font-size: 14px;
}

.info img {
  margin-right: 5px;
}

.localization,
.hour,
.hunts {
  display: flex;
  align-items: center;
}

.localization img {
  width: 15px;
  height: 15px;
}

.hour img {
  width: 15px;
  height: 15px;
}

.hunts img {
  width: 15px;
  height: 15px;
}

.create {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-extralight);
  border-radius: 0px 0px 20px 0px;
}

.detail {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-extralight);
  border-radius: 0px 0px 0px 20px;
}

.detail-create {
  display: flex;
  height: 49px;
}

.initials-container {
  display: flex;
  margin-top: 5px;
}

.initials {
  background-color: var(--color-grey-light);
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 64px;
  font-family: 'Gilroy Regular';
  color: var(--color-orange-primary);
  font-size: 12px;
  padding-top: 2px;
}

.images-container img {
  object-fit: cover;
  object-position: center;
}

@media (min-width: 600px) {
  .photos-grid {
    grid-template-rows: 20vw;
  }
  .photos-grid.two-rows {
    grid-template-rows: 10vw 10vw;
  }
}
