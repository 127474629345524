.content {
    background-color: var(--color-white-background-light);
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.heading {
    text-align: center;
    font-family: 'Gilroy Medium';
    font-size: 38px;
}
.heading2 {
    text-align: center;
    font-family: 'Gilroy Regular';
    font-size: 38px;
}
.heading3 {
    text-align: center;
    font-family: 'Gilroy Bold';
    color: var(--color-orange-primary);
    font-size: 35px;
}

.paragraph {
    margin-top: 40px;
    margin-bottom: 50px;
    font-family: Gilroy Medium, sans-serif;
    font-size: 20px;
    text-align: center;
    max-width: 600px;
}

.text-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--color-white-background-light);
    padding: 80px 50px 90px;

}

.app-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.call-to-action {
    background-image: url('assets/images/landingPage/landscape_mini.jpg');
    background-size: cover;
    height: 620px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.image {
    display: none;
}

.image-mini {
    display: block;
    max-width: 600px;
    width: 90%;
}

@media (min-width: 600px) {
    .call-to-action {
        background-image: url('assets/images/landingPage/landscape.jpg');
        background-size: cover;
        height: 620px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .image {
        display: block;
        max-width: 750px;
        width: 90%;
    }
    .image-mini {
        display: none;
    }
}

.last-button {
    margin: 20px 0 50px 0;
}
