.label {
  cursor: pointer;
  border: 1px dashed var(--color-orange-primary);
  height: 105px;
  width: 105px;
  font-weight: bold;
  display: block;
  position: static;
  border-radius: 20px;
  background-color: var(--color-orange-extralight);
  display: flex;
  align-items: center;
  justify-content: center;
}

.label img {
  margin-top: 10px;
  margin-left: 10px;
}

.picture {
  border: none;
  position: relative;
}

.picture img {
  border-radius: 20px;
  height: 105px;
  width: 105px;
  margin-top: 0;
  margin-left: 0;
  object-fit: cover;
  object-position: center;
}

.input {
  display: none;
}

.pictures-container {
  margin: 0 auto;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 106px);
  grid-template-rows: repeat(auto-fill, 106px);
  justify-content: space-around;
}

.close {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-light);
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.close img {
  width: 20px;
  height: 20px;
}
