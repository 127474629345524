.container {
  display: flex;
  flex-direction: column;
  background-image: url('assets/icons/common/appOrangeBackground.svg');
  background-position: 0 -50px;
  background-size: cover;
  height: 100vh;
}

.title-and-avatar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0 25px 0;
}

.no-title-and-avatar-container {
  margin: 25px 0;
}

.not-up-to-date .title-and-avatar-container {
  margin: 60px 0 25px 0;
}

.with-photos-container .content-and-up-to-date-container {
  margin-bottom: 60px;
}

.content-and-up-to-date-container {
  padding: 0 15px;
}

.not-up-to-date .content-and-up-to-date-container {
  margin-bottom: 60px;
  padding: 0 15px;
}

.user-page.not-up-to-date .content-and-up-to-date-container {
  margin-bottom: 0;
}

.not-up-to-date.home-page .content-and-up-to-date-container {
  margin-bottom: 0;
}

.return-and-title-container {
  display: flex;
  align-items: center;
}

.signin {
  display: none;
}



.return {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--color-white);
  border-radius: 8px;
  cursor: pointer;
  margin-right: 15px;
}

.return img {
  width: 15px;
  height: 15px;
  filter: invert(67%) sepia(19%) saturate(2855%) hue-rotate(324deg)
    brightness(98%) contrast(106%);
}

.title-and-date-container {
  color: var(--color-white);
}

.date {
  font-family: 'Gilroy Regular';
}

@media (min-width: 600px) {
  .signin {
    display: block;
    position: absolute;
    right: 20px;
  }
  .container {
    margin-left: 95px;
    width: calc(100% - 95px);
    background-position: 0 -320px;
  }
  .content {
    background-image: url('assets/icons/common/appOrangeBackgroundLarge.svg');
    background-size: cover;
  }
  .with-return-container .content-and-up-to-date-container {
    padding: 30px 30px;
    max-width: 1000px;
    display: flex;
    align-items: center;
  }

  .with-return-container .content-and-up-to-date-container > div {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 90px;
  }
  .not-up-to-date .content-and-up-to-date-container {
    margin-bottom: 50px;
  }
  .with-white-container.not-up-to-date .content-and-up-to-date-container {
    margin-bottom: 0;
  }
  .not-up-to-date .content-and-up-to-date-container > div {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 90px;
  }
  .with-photos-container .content-and-up-to-date-container {
    margin-bottom: 20px;
  }
  .content-and-up-to-date-container {
    padding: 0 30px;
    display: flex;
    gap: 20px;
  }
  .with-white-container .content-and-up-to-date-container > div {
    height: 120px;
    margin-bottom: 0;
  }
  .with-white-container .content-and-up-to-date-container > div {
    margin-top: 30px;
    position: relative;
    top: 20px;
  }
  .title-and-avatar-container {
    margin: 0;
    height: 120px;
  }
  .with-white-container .title-and-avatar-container {
    background-color: var(--color-white-background);
    width: 450px;
    height: 120px;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 0;
    margin-top: 30px;
    position: relative;
    top: 20px;
  }
  .title-and-avatar-container.with-photos {
    padding: 10px 0 30px 0;
  }
  .with-white-container .title-and-date-container {
    color: var(--color-black);
  }
  .user-page.not-up-to-date .content-and-up-to-date-container {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .without-navigation.container {
    margin-left: 0;
    width: 100%;
  }
}

.header-icon img {
  width: 104px;
}
