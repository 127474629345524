.container input {
  width: 100%;
  padding: 0 12px;
  padding-top: 25px;
  background-color: transparent;
  border: 1px solid var(--color-grey-inactive);
  border-radius: 20px;
  height: 75px;
  font-family: 'Gilroy Regular';
  color: var(--color-black);
  font-size: 16px;
}

.container div:nth-of-type(2) {
  margin-bottom: 20px;
}

.container label {
  position: absolute;
  top: 19px;
  left: 12px;
  font-family: 'Gilroy Medium';
}

.error {
  padding-top: 10px;
  padding-bottom: 15px;
  color: red;
}
