.container {
    background-color: var(--color-grey-background);
    width: 100%;
    padding: 15px;
}
.contentImage {
    max-width: 800px;
    width: 100%;
}
.content {
    padding: 10px 0;
}
b {
    font-family: 'Gilroy Bold';
}

.footer {
    background-color: var(--color-grey-background);
}
