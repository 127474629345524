.container {
    background-color: var(--color-white-background);
    border-radius: 20px;
    box-shadow: 10px 10px 50px 0 var(--color-shadow);
    padding: 20px;
}
.security-container {
    background-color: var(--color-orange-background-light);
    border-radius: 20px;
    box-shadow: 10px 10px 50px 0 var(--color-shadow);
    padding: 20px;
}

.security-info {
    color: var(--color-orange-primary);
    font-family: 'Gilroy Medium';
    white-space: pre-line;
}

.info {
    color: var(--color-dark-grey);
    font-family: 'Gilroy Medium';
    white-space: pre-line;
}
