.container input[type='file'] {
  display: none;
}

.container label {
  padding: 15px 8px;
  border: none;
  background-color: var(--color-grey-light);
  border: 1px solid var(--color-grey-light);
  border-radius: 40px;
  color: var(--color-black);
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  font-family: 'Gilroy Regular', sans-serif;
}

.container label:hover,
.container label:active {
  background-color: var(--color-grey-extralight);
  cursor: pointer;
}

.file-name {
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

.choosen-text {
  margin-bottom: 5px;
  text-decoration: underline;
}

.info {
  margin: 10px;
  font-size: 12px;
  font-color: var(--color-grey)
}
