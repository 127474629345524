.authentication-app-logo-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.authentication-image-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.form {
  max-width: 400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  justify-content: center;
}

.title {
  margin-top: 35px;
}
.title h1 {
  font-size: 32px;
}

.container a {
  display: block;
  text-decoration: none;
  margin-top: 8px;
  font-family: 'Gilroy Regular';
  color: var(--color-grey-inactive);
  font-size: 16px;
}

.container a:hover {
  text-decoration: underline;
}

.form input {
  width: 100%;
  padding: 0 12px;
  padding-top: 25px;
  background-color: transparent;
  border: 1px solid var(--color-grey-inactive);
  border-radius: 20px;
  margin-top: 20px;
  height: 75px;
  font-family: 'Gilroy Regular';
  color: var(--color-black);
  font-size: 16px;
}

input[type='firstname'] {
  width: 169px;
}

input[type='lastname'] {
  width: 169px;
  position: absolute;
  top: -95px;
  left: 180px;
}

label[for='lastname'] {
  position: absolute;
  top: -60px;
  left: 192px;
}

.form input:focus {
  border: 2px solid var(--color-black);
  outline: none;
}

.error {
  margin-top: 0.4rem;
  font-style: italic;
  font-size: 12px;
  color: red;
}

.error::before {
  content: '• ';
}

.actions button {
  margin-top: 20px;
  width: 100%;
}

.forgot-password-container {
  margin-top: 10px;
  display: flex;
  justify-content: right;
  gap: 10px;
  font-family: 'Gilroy Regular';
  color: var(--color-grey-inactive);
  font-size: 16px;
}


.toggle-mode-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-family: 'Gilroy Regular';
  color: var(--color-grey-inactive);
  font-size: 16px;
}
