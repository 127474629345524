.plan-container {
  background-color: var(--color-orange-extralight);
  padding: 15px;
  border-radius: 20px;
}

.infos {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.info-icon {
  width: 20px;
  filter: invert(64%) sepia(22%) saturate(2031%) hue-rotate(325deg)
    brightness(100%) contrast(103%);
}

.info p {
  margin-bottom: 5px;
  color: var(--color-orange-primary);
}

.info a {
  text-decoration: underline;
  color: var(--color-orange-primary);
  font-family: 'Gilroy Bold';
}

.input {
  display: none;
}

.input-container input[type='text'] {
  border-radius: 20px;
  padding-top: 7px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label-plan {
  position: unset;
  margin-left: 3px;
}

.input-plan {
  width: 100%;
  padding: 5px 12px;
  border: 1px solid var(--color-grey-inactive);
  font-family: 'Gilroy Regular';
  color: var(--color-black);
  font-size: 16px;
}

.label {
  cursor: pointer;
  border: 1px dashed var(--color-orange-primary);
  position: static;
  border-radius: 20px;
  background-color: var(--color-orange-extralight);
  align-self: end;
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.default img {
  margin-top: 10px;
  margin-left: 10px;
}

.plan-image {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.plan-image img {
  border-radius: 20px;
  height: 300px;
  width: 300px;
  margin-top: 0;
  margin-left: 0;
  object-fit: cover;
  object-position: center;
}

.image-and-close-button-container {
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-light);
  border-radius: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.plans-container {
  margin-bottom: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-around;
}
