.container {
  background-color: var(--color-white-background);
  border-radius: 20px;
}

.images-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  padding: 20px;
}

.plan-name {
  font-family: 'Gilroy Bold';
  margin-bottom: 20px;
}

.name-and-image-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  overflow: hidden;
}
