.container {
  padding: 0 15px;
}

.container input {
  width: 100%;
  padding: 0 12px;
  padding-top: 25px;
  background-color: transparent;
  border: 1px solid var(--color-grey-inactive);
  border-radius: 20px;
  height: 75px;
  font-family: 'Gilroy Regular';
  color: var(--color-black);
  font-size: 16px;
  margin-bottom: 20px;
}

.container label {
  position: absolute;
  top: 19px;
  left: 12px;
  font-family: 'Gilroy Medium';
}

.lastname-and-firstname-container {
  display: flex;
  gap: 20px;
}

.lastname-and-firstname-container div {
  width: 100%;
}

.error {
  color: red;
  margin-bottom: 20px;
}
