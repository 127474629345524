.container {
  background-color: var(--color-white);
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 10px 10px 50px var(--color-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.photo-card-and-name {
  display: flex;
  align-items: center;
  font-family: 'Gilroy Bold', 'sans-serif';
  border-bottom: 1px solid var(--color-grey-extralight);
}

.photo {
  width: 110px;
  height: 110px;
  padding: 15px;
}

.photo img {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  font-size: 14px;
}

.info img {
  margin-right: 5px;
}

.title {
  width: 50%;
}

.localization,
.hour,
.hunts {
  display: flex;
  align-items: center;
}

.localization img {
  width: 15px;
  height: 15px;
}

.hour img {
  width: 15px;
  height: 15px;
}

.hunts img {
  width: 15px;
  height: 15px;
  margin-bottom: 2px;
}

.hunts p {
  color: var(--color-green-light);
}

.create {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-extralight);
  border-radius: 0px 0px 20px 0px;
}

.detail {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-extralight);
  border-radius: 0px 0px 0px 20px;
}

.detail-create {
  display: flex;
  height: 49px;
}
