.container {
  border: 1px solid var(--color-white);
  border-radius: 16px;
  background-color: var(--color-orange-extralight);
  width: 80px;
  height: 80px;
  font-family: 'Gilroy Bold', 'sans-serif';
  color: var(--color-orange-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
