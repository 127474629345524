.call-to-action {
  padding: 15px 8px;
  background-color: var(--color-orange-primary);
  border-radius: 40px;
  color: var(--color-white-background);
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--color-orange-primary);
  font-family: 'Gilroy Regular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.call-to-action:hover,
.call-to-action:active {
  filter: brightness(1.1);
  cursor: pointer;
}

.inversed-call-to-action {
  padding: 15px 8px;
  background-color: var(--color-white-background);
  border-radius: 40px;
  color: var(--color-orange-primary);
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--color-orange-primary);
  font-family: 'Gilroy Regular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.inversed-call-to-action:hover,
.inversed-call-to-action:active {
  filter: brightness(0.95);
  cursor: pointer;
}

.red {
  padding: 15px 8px;
  background-color: var(--color-red-light);
  border-radius: 40px;
  color: var(--color-white);
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--color-white);
  font-family: 'Gilroy Regular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.red:hover,
.red:active {
  filter: brightness(0.95);
  cursor: pointer;
}
