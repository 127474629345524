.header {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 80px;
  background-color: var(--color-white-background);
  padding: 20px;
}

nav,
ul {
  height: 100%;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  list-style-type: none;
  font-size: 14px;
}

.list a {
  text-decoration: none;
  color: var(--color-grey-inactive);
  font-size: 12px;
}

.list a img {
  width: 20px;
  height: 20px;
  filter: invert(69%) sepia(0%) saturate(2008%) hue-rotate(142deg)
    brightness(88%) contrast(88%);
}

.list a:hover,
.list a.active {
  color: var(--color-orange-primary);
}

.list a:hover img,
.list a.active img {
  filter: invert(73%) sepia(31%) saturate(5232%) hue-rotate(326deg)
    brightness(101%) contrast(104%);
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 100%;
}

.list a .domain-image img {
  width: 18px;
  height: 18px;
}

@media (min-width: 600px) {
  .header {
    position: fixed;
    left: 0;
    z-index: 99;
    width: 95px;
    height: 100%;
    padding-top: 30px;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 5%;
  }
  .list a {
    white-space: nowrap;
  }
}
