.button {
  background-color: var(--color-grey-light);
  padding: 8px 20px;
  border-radius: 24px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  border: none;
}

.button:hover {
  background-color: var(--color-orange-primary);
  color: var(--color-white);
  padding: 8px 20px;
  border-radius: 24px;
  cursor: pointer;
}

.active {
  background-color: var(--color-orange-primary);
  color: var(--color-white);
}
