.species {
  border: 1px solid;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  width: fit-content;
}
.species-without-info {
  border: 1px solid;
  border-radius: 20px;
  padding: 7px 10px 5px 10px;
  width: fit-content;
  font-size: 13px;
  margin: 0 5px 5px 0;
}
