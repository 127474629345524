.container {
  display: flex;
  color: var(--color-grey-inactive);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 0px 15px;
}

.container .active {
  color: var(--color-orange-primary);
  border-bottom: 1px solid var(--color-orange-primary);
}

.my-hunts {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-grey-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.upcoming-hunts {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-grey-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.past-hunts {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-grey-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
