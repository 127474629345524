.title {
  margin-bottom: 20px;
  font-family: 'Gilroy Bold';
  display: flex;
  align-items: center;
}

.title-text {
  margin-right: 5px;
}

.container input {
  width: 100%;
  padding: 0 12px;
  padding-top: 25px;
  background-color: transparent;
  border: 1px solid var(--color-grey-inactive);
  border-radius: 20px;
  margin-bottom: 20px;
  height: 75px;
  font-family: 'Gilroy Regular';
  color: var(--color-black);
  font-size: 16px;
}

.container label {
  position: absolute;
  top: 19px;
  left: 12px;
  font-family: 'Gilroy Medium';
}

.animal-and-quota-container {
  display: flex;
  gap: 20px;
}

.animal-and-quota-container div {
  width: 100%;
}

.close {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-light);
  border-radius: 20px;
  cursor: pointer;
}

.close img {
  width: 20px;
  height: 20px;
}

.error {
  padding-bottom: 10px;
  color: red;
}
