.container {
  padding: 0 15px;
}

.button {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.button button {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  gap: 10px;
}

@media (min-width: 600px) {
  .container {
    padding: 0 30px;
    position: relative;
    top: 20px;
    padding-bottom: 20px;
  }
  .buttons button {
    flex-grow: unset;
    width: 195px;
  }
  .button button {
    width: 280px;
  }
}
