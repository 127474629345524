.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-container {
  width: 100%;
  height: 100vh;
  z-index: 30;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal {
  max-width: 450px;
  background-color: white;
  padding: 20px 30px;
  margin: 0px 20px 50px 20px;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  animation: slide-down 300ms ease-out forwards;
}

.close-container {
  display: flex;
  flex-direction: row-reverse;
}

.close-container img {
  width: 40px;
  cursor: pointer;
}

.title-container {
  font-family: 'Gilroy Bold';
  font-size: 20px;
  padding-bottom: 20px;
}

.message-container {
  padding-bottom: 20px;
}

.confirm-button-container {
  padding-bottom: 20px;
}

.confirm-button-container button {
  width: 100%;
}

.cancel-button-container {
  padding-bottom: 20px;
}

.cancel-button-container button {
  width: 100%;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 600px) {
  .modal-container {
    margin-left: 95px;
    width: calc(100% - 95px);
  }
}
