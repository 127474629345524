.container {
  display: flex;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 15px;
}

.icon-container {
  padding: 10px !important;
  border-radius: 8px;
  margin-right: 25px;
  background-color: var(--color-orange-extralight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container img {
  filter: invert(64%) sepia(22%) saturate(2031%) hue-rotate(325deg)
    brightness(100%) contrast(103%);
  width: 25px;
  height: 25px;
}
