.content {
  background-color: var(--color-white-background);
  border-radius: 20px 20px 0 0;
  padding: 0 15px;
}

.icon {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification {
  background-color: var(--color-red-light-background) !important;
}

.notification div {
  color: var(--color-red-light) !important;
}

.submit-button-container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submit-button-container button {
  width: 100%;
}

@media (min-width: 600px) {
  .wrapper {
    background-color: var(--color-grey-background);
    padding: 40px;
  }
  .content {
    padding: 15px;
    border-radius: 20px;
    background-color: var(--color-white-background);
    max-width: 1000px;
    margin: 0 auto;
  }
}
