.container {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 40px 0 40px 0;
  white-space: nowrap;
  overflow: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}
