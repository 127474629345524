.container {
  background-color: var(--color-white-background);
  height: 100%;
  border-radius: 20px 20px 0 0;
}

.documents,
.update-profile,
.logout {
  display: flex;
  align-items: center;
  padding: 30px;
  border-bottom: 1px solid var(--color-grey-extralight);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.documents:hover,
.update-profile:hover,
.logout:hover {
  background-color: var(--color-grey-extralight);
}

.documents img,
.update-profile img,
.logout img {
  margin-right: 15px;
}

@media (min-width: 600px) {
  .container {
    border-radius: 0px;
  }
}
