.container {
  margin: 50px 0;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-container label {
  top: unset;
  left: unset;
  width: 150px;
  height: 150px;
}

.container input {
  display: none;
}

.avatar-container {
  position: relative;
  cursor: pointer;
}

.avatar {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-orange-extralight);
  width: 100%;
  height: 100%;
}

.current-user-avatar img {
  object-fit: cover;
  object-position: center;
  width: 160px;
  height: 160px;
}

.avatar-file img {
  object-fit: cover;
  object-position: center;
  width: 160px;
  height: 160px;
}

.avatar-default-icon img {
  width: 100px;
  margin-top: 40px;
}

.pen {
  background-color: var(--color-blue-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pen img {
  width: 20px;
  filter: invert(100%) sepia(0%) saturate(1021%) hue-rotate(168deg)
    brightness(110%) contrast(101%);
}
