.container {
  padding: 15px 15px 100px 15px;
}

.container button {
  width: 100%;
  padding: 12px 8px;
}

.header {
  display: flex;
  border-bottom: 1px solid var(--color-grey-extralight);
  padding-bottom: 15px;
}

.user-container {
  display: flex;
  gap: 10px;
}

.return {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--color-grey-light);
  border-radius: 8px;
  cursor: pointer;
  margin-right: 15px;
}

.return img {
  width: 15px;
  height: 15px;
}

.title {
  font-family: 'Gilroy Bold';
}

.documents-container {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.licence,
.validation,
.insurance {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licence label,
.validation label,
.insurance label {
  cursor: pointer;
  font-weight: bold;
  display: block;
  position: static;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
}

.licence img,
.validation img,
.insurance img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  overflow: hidden;
}

.licence p,
.validation p,
.insurance p {
  font-family: 'Gilroy Bold';
}

@media (min-width: 600px) {
  .container {
    margin-left: 95px;
    width: calc(100% - 95px);
  }
}
