.add {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.add img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.warning {
  padding: 4px 0 3px 10px;
  margin: 0 0 10px 20px;
  color: var(--color-grey);
  border-left: 5px solid var(--color-grey-extralight);
}
