.content {
  background-color: var(--color-white-background-light);
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.greetings-and-avatar-container {
  display: flex;
  justify-content: space-between;
}
