.container {
  width: 100%;
}

.date {
  font-family: 'Gilroy Bold', 'sans-serif';
}

.date-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.date-container img {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 7px;
}
