.container {
  background-color: var(--color-white-background);
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  padding-top: 5px;
}

.participant-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 8px 15px;
}

.initials-name-documents-container {
  display: flex;
  align-items: center;
}

.name-documents-container {
  margin-left: 15px;
}

.name-certification-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-name,
.user-documents {
  font-family: 'Gilroy Regular';
}

.user-name {
  padding-top: 2px;
}

.user-documents {
  color: var(--color-grey-inactive);
  font-size: 14px;
}

.invite-container {
  align-items: center;
  border-top: 1px solid var(--color-grey-extralight);
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  box-shadow: 10px 10px 50px var(--color-shadow);
}

.owner-actions {
  display: flex;
  gap: 5px;
}

.see-invited-user-documents {
  background-color: rgb(255 130 77 / 0.1);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.remove-invited-user {
  background-color: rgb(255 49 66 / 0.1);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.remove-invited-user img {
  width: 20px;
  height: 20px;
  filter: invert(30%) sepia(78%) saturate(2573%) hue-rotate(337deg)
    brightness(104%) contrast(110%);
}
