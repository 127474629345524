.container button {
  width: 100%;
  padding: 12px 8px;
}

.header {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--color-grey-extralight);
}

.user-container {
  display: flex;
  gap: 10px;
}

.return {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--color-grey-light);
  border-radius: 8px;
  cursor: pointer;
  margin-right: 15px;
}

.return img {
  width: 15px;
  height: 15px;
}

.title {
  font-family: 'Gilroy Bold';
}

.photos-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 15px 100px 15px;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 20px;
}

.photo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.photo img {
  width: 100%;
  max-height: 1000px;
  object-fit: cover;
  border-radius: 20px;
  overflow: hidden;
}

.document-label {
  font-family: 'Gilroy Bold';
}

@media (min-width: 600px) {
  .container {
    margin-left: 95px;
    width: calc(100% - 95px);
  }
}
