.content {
  background-color: var(--color-grey-background);
  width: 100%;
  padding: 0 15px 0 15px;
}

.content-with-photos {
  background-color: transparent;
  position: relative;
  top: -60px;
}

.footer {
  background-color: var(--color-grey-background);
}

@media (min-width: 600px) {
  .wrapper {
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
