.whatsapp-button {
    background-color: var(--color-green-whatsapp);
    border-radius: 10px;
    color: var(--color-white);
    border: 1px solid var(--color-green-whatsapp);
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    font-family: 'Gilroy Regular', sans-serif;
    display: flex;
    align-items: center;
    padding: 7px 10px;
    margin-top: 5px;
    max-width: 190px;
    gap: 10px;
}

.whatsapp-button:hover,
.whatsapp-button:active {
    filter: brightness(1.1);
    cursor: pointer;
}

.logo {
    max-height: 25px;
}
