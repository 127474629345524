.default-avatar {
  width: 50px;
  height: 50px;
  background-color: var(--color-orange-background-light);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.default-avatar img {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  filter: invert(51%) sepia(96%) saturate(436%) hue-rotate(326deg)
    brightness(106%) contrast(101%);
}

.user-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.user-avatar img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.default-avatar:hover,
.user-avatar:hover {
  cursor: pointer;
}

.avatar-select {
  position: absolute;
  top: 40px;
  right: 0;
  vertical-align: middle;
}

.logout {
  font-family: 'Gilroy Regular', sans-serif;
  font-size: 14px;
  background-color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 5px;
  padding: 6px 20px 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: var(--color-black);
}

.logout:hover {
  background-color: var(--color-grey-extralight);
  border: 1px solid var(--color-grey-extralight);
}
