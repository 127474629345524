.select-container {
  border: 1px solid var(--color-grey-inactive);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 75px;
  padding: 12px;
}

.select-container label {
  font-family: 'Gilroy Medium';
  position: static;
}

.select-container select {
  border: none;
  font-family: 'Gilroy Medium';
  font-size: 16px;
  background-color: var(--color-white-background);
  width: 100%;
  height: 100%;
}

.select-container select:focus,
.select-container select:active {
  outline: none;
}

.error {
  color: red;
  padding-top: 10px;
}
