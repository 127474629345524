.container {
    display: flex;
    flex-direction: row;
    align-items:center;
    font-size: 14px;
}

.species-container {
    min-width: 50%;
}
.quota{
    color: var(--color-grey-inactive);
}
.ring{
    color: var(--color-grey-inactive);
}
