.wrapper {
  background-color: var(--color-white-background);
}

.content {
  border-radius: 20px 20px 0 0;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
}

@media (min-width: 600px) {
  .content {
    border-radius: 0;
  }
}
