@font-face {
  font-family: 'Gilroy Bold';
  src: url('assets/fonts/Gilroy-Bold.ttf');
}

@font-face {
  font-family: 'Gilroy Regular';
  src: url('assets/fonts/Gilroy-Regular.ttf');
}

@font-face {
  font-family: 'Gilroy Medium';
  src: url('assets/fonts/Gilroy-Medium.ttf');
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --color-black: #333333;

  --color-blue-primary: #1677ff;

  --color-green-light: #00b578;
  --color-green-light-background: #dffaf1;
  --color-green-whatsapp: #5eba3c;

  --color-grey-background: #f5f5f5;
  --color-grey-light: #dddbdb;
  --color-grey-extralight: #eeeeee;
  --color-grey-inactive: #999999;
  --color-grey: #6f6f6f;
  --color-dark-grey: #4a4a4a;
  --color-shadow: rgba(52, 64, 84, 0.12);

  --color-orange-background: linear-gradient(180deg, #ff824d 0%, #ff984d 100%);
  --color-orange-primary: #ff824d;
  --color-orange-background-light: #fff9ed;
  --color-orange-extralight: #ff8f1f1a;

  --color-red-danger: #ff3142;
  --color-red-light: #cf544e;
  --color-red-light-background: #f9e1e0;

  --color-white: #ffffff;
  --color-white-background-light: #f5f5f5;
  --color-white-background: #ffffff;
  --color-white-shadow: rgba(255, 255, 255, 0.5);
}

body {
  font-family: 'Gilroy Medium', sans-serif;
  font-size: 16px;
  background-color: var(--color-white-background-light);
  color: var(--color-black);
  width: 100vw;
  height: 100vh;
}

main {
  height: 100%;
}

h1 {
  font-family: 'Gilroy Medium', 'sans-serif';
  font-size: 24px;
}

h3 {
  font-family: 'Gilroy Bold', 'sans-serif';
  font-size: 20px;
}

button {
  font-family: 'Gilroy Medium', sans-serif;
}

ul {
  list-style-type: none;
}

.ant-picker {
  padding: 12px;
  border: 1px solid var(--color-grey-inactive);
  border-radius: 20px;
}

.ant-picker-input {
  align-items: center;
}

.ant-picker-input input {
  font-family: 'Gilroy Regular';
  padding-top: 2px !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__annotations {
  display: none;
}

.notification-success {
  background-color: var(--color-green-light-background) !important;
}

.notification-success div {
  color: var(--color-green-light) !important;
}

.notification-error {
  background-color: var(--color-red-light) !important;
}

.notification-error div {
  color: var(--color-white) !important;
}
