.invitation-button {
    background-color: var(--color-orange-primary);
    border-radius: 6px;
    color: var(--color-white);
    border: 1px solid var(--color-orange-primary);
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    font-family: 'Gilroy Regular', sans-serif;
    display: flex;
    align-items: center;
    padding: 7px 10px;
    margin-top: 5px;
    max-width: 220px;
    gap: 10px;
}

.invitation-button:hover,
.invitation-button:active {
    filter: brightness(1.1);
    cursor: pointer;
}

.logo {
    max-height: 20px;
    padding-bottom: 2px;
    filter: invert(99%) sepia(18%) saturate(224%) hue-rotate(246deg) brightness(117%) contrast(100%);
}
