.footer {
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('assets/icons/common/appFooter.svg');
  background-position: top;
  background-size: cover;
  padding-bottom: 80px;
  width: 100%;
}

.app-icon {
  margin-top: 15px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
  color: var(--color-white);
  font-family: 'Gilroy Regular', sans-serif;
}

.footer-links a {
  color: var(--color-white);
  font-family: 'Gilroy Regular', sans-serif;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

@media (min-width: 600px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    height: 120px;
    background-image: url('assets/icons/common/appFooterNoRadius.svg');
  }
  .footer-icon {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-icon img {
    width: 90px;
    margin-left: 60px;
  }
  .app-icon {
    margin: 0;
  }
  .footer-links {
    margin: 0;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    gap: 30px;
    margin-right: 50px;
    font-size: 14px;
  }
}
