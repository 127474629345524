.hunt-plan {
    border-top: 1px solid var(--color-grey-extralight);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    padding: 10px 0;
    margin-top: 10px;
}

.section-title{
    font-family: 'Gilroy Bold';
    font-size: 16px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
}

.warning {
    padding: 4px 0 3px 10px;
    margin: 0 0 10px 20px;
    color: var(--color-grey);
    border-left: 5px solid var(--color-grey-extralight);
}
