.container {
  padding-bottom: 100px;
}

.actions-container {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.actions-container button {
  width: 100%;
}
