.title {
  background-color: var(--color-grey-light);
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid var(--color-grey-extralight);
  display: flex;
  cursor: pointer;
}

.title:hover {
  background-color: var(--color-grey-light);
}

.title img {
  margin: 0 10px 0 10px;
  width: 20px;
}

.inactive img {
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);
}

.active img {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.content {
  padding: 10px 20px;
  font-family: 'Gilroy Medium';
  white-space: pre-line;
  background-color: var(--color-white);
}
