.container {
  display: flex;
  min-height: 50px;
  width: 100%;
}

.first {
  flex-grow: 1;
  display: flex;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid var(--color-grey-extralight);
  border-radius: 0px 0px 0px 20px;
  font-size: 14px;
  cursor: pointer;
}

.first div {
  display: flex;
}

.first div img {
  margin-right: 5px;
}

.second {
  width: 50%;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid var(--color-grey-extralight);
  border-radius: 0px 0px 20px 0px;
  font-size: 14px;
  cursor: pointer;
}

@media (min-width: 600px) {
  .with-photos .first {
    border-radius: 0px;
  }
}
