.content {
  background-color: var(--color-grey-background);
  width: 100%;
  padding: 0 15px;
}

.pictures-container div div:nth-of-type(1) {
  padding-top: unset;
}

.pictures-container button {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.content-with-photos {
  background-color: transparent;
  position: relative;
  top: -60px;
}

.notification-success {
  background-color: var(--color-green-light-background) !important;
}

.footer {
  background-color: var(--color-grey-background);
}

.delete-button button {
  width: 100%;
  margin-top: 40px;
}

@media (min-width: 600px) {
  .content {
    padding: 0 30px;
  }
  .sub-content {
    max-width: 800px;
    margin: 0 auto;
  }
}
