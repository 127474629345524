.container {
  position: relative;
}

.container input::placeholder {
  font-family: 'Gilroy Regular';
  color: var(--color-grey-inactive);
  font-size: 16px;
}

.error {
  margin-top: 0.4rem;
  font-style: italic;
  font-size: 12px;
  color: red;
  display: flex;
}
.error::before {
  content: '• ';
  margin-right: 5px;
}

label {
  position: absolute;
  top: 37px;
  left: 12px;
  font-family: 'Gilroy Medium';
}

.list {
  list-style-type: none;
}
