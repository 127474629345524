.grid {
  max-width: 400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  justify-content: center;
  text-align: center;
  height: calc(100% - 120px);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container img {
  margin: 70px auto 30px auto;
}

.container h3 {
  margin: 0 auto 15px auto;
}

.container p {
  margin: 0 auto 30px auto;
}

.container button {
  width: 100%;
  margin-bottom: 40px;
}
