.container {
  padding: 0 15px;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttons button {
  flex-grow: 1;
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  gap: 10px;
}

.list-container {
  width: 100%;
}

.notification-success {
  background-color: var(--color-green-light-background) !important;
}

.notification-success div {
  color: var(--color-green-light) !important;
}

@media (min-width: 600px) {
  .container {
    padding: 0 30px;
    position: relative;
    top: 20px;
  }
  .buttons button {
    flex-grow: unset;
    width: 195px;
  }
}
