.content {
  background-color: var(--color-white-background-light);
  width: 100%;
  border-radius: 20px 20px 0 0;
  flex-grow: 1;
}

.footer {
  background-color: var(--color-white-background-light);
}

@media (min-width: 600px) {
  .content {
    border-radius: 0px;
  }
}
