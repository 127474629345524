.date-and-time-container {
  display: flex;
  gap: 20px;
}

.date-and-time-container div {
  width: 50%;
}

.error {
  color: red;
  padding-top: 10px;
}
