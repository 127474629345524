.container {
  background-color: var(--color-grey-light);
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 64px;
}

.container p {
  font-family: 'Gilroy Regular';
  color: var(--color-orange-primary);
  font-size: 12px;
  padding-top: 2px;
}
